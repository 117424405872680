<template>
  <v-app>
    <header>
      <v-app-bar>
        <v-app-bar-title>
          <nav>
            <ul>
              <li>
                <router-link to="/" :active-class="false"  @click="store.commit('scrollToTop')">
                  <div>
                    <v-img
                      width="32"
                      src="../public/logo.png"
                      alt="logo"
                    >
                    </v-img>
                  </div>
                  <b>ARCHITECTURE</b>
                </router-link>
              </li>
            </ul>
          </nav>
        </v-app-bar-title>
        <nav>
          <ul id="leftMenu">
            <li><router-link to="/" @click="store.commit('scrollToTop')">Strona główna</router-link></li>
            <li><router-link to="/kontakt" @click="store.commit('scrollToTop')">Kontakt</router-link></li>
          </ul>
        </nav>
        <v-app-bar-nav-icon 
          variant="text"
          @click.stop="drawer = !drawer"
        />
      </v-app-bar>
      <v-navigation-drawer
        v-model="drawer"
        location="top"
        temporary
      >
        <nav>
          <ul>
            <li><router-link to="/" @click="store.commit('scrollToTop')">Strona główna</router-link></li>
            <li><router-link to="/kontakt" @click="store.commit('scrollToTop')">Kontakt</router-link></li>
          </ul>
        </nav>
      </v-navigation-drawer>
    </header>
    <v-main>
      <router-view/>
    </v-main>
    <footer>
      Architecture &copy; 2024 | Wszelkie prawa zastrzeżone
    </footer>
  </v-app>
</template>

<script setup>
import { defineModel } from 'vue'
import { useStore } from 'vuex'
const drawer = defineModel()
const store = useStore()
</script>

<style lang="scss">
#app {
  font-family: Inter, sans-serif;
  font-feature-settings: 'liga' 1, 'calt' 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.v-toolbar__content
{
  margin-right: auto;
  margin-left: auto;
  max-width: 1328px !important;

  .v-toolbar-title__placeholder a
  {
    width: 210px;
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;

    >div
    {
      margin-right: 20px;
    }
  }

  #leftMenu
  {
    list-style-type: none;

    >li
    {
      float: left;
      margin-right: 25px;

      >a
      {
        color: black;
        font-weight: bold;
        text-decoration: none;
      }

      >a:hover
      {
        color: #42b983;
      }

      >.router-link-exact-active
      {
        color: #1a90e5;
      }
    }
  }

  @media not screen and (min-width: 600px)
  {
    #leftMenu
    {
      display: none;
    }
  }

  @media (min-width: 600px)
  {
    button
    {
      display: none;
    }
  }
}

.v-navigation-drawer__content
{
  li
  {
    font-weight: bold;

    >a
    {
      display: block;
      width: 100%;
      padding: 25px;
      color: black;
      text-decoration: none;
    }

    >.router-link-exact-active
    {
      color: #42b983;
    }
  }

  li:first-child:after
  {
    content: "";
    position: absolute;
    left: 10px;
    width: calc(100% - 20px);
    border-bottom: #5d5d5d solid 1px;
  }
}

footer
{
  font-size: 14px;
  background-color: #202020;
  color: white;
}

@media not screen and  (min-width: 450px)
{
  footer
  {
    padding: 10px;
    text-align: center;
  }
}

@media (min-width: 450px)
{
  footer
  {
    padding: 10px 10px 10px 25px;
  }
}
</style>
