import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomeView.vue'
import Contact from '../views/ContactView.vue'
import PrivacyPolicy from '../views/PrivacyPolicyView.vue'

const routes = [
  {
    path: '/',
    component: Home,
    meta: { title: 'Home - ARCHITECTURE' }
  },
  {
    path: '/kontakt',
    component: Contact,
    meta: { title: 'Kontakt - ARCHITECTURE' }
  },
  {
    path: '/polityka-prywatnosci',
    component: PrivacyPolicy,
    meta: { title: 'Polityka prywatności - ARCHITECTURE' }
  },
  {
    path: "/:catchAll(.*)",
    meta: { requiresAuth: false }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: "/",
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.afterEach((to, next) =>
{
  document.title = to.meta.title
  next
})

export default router