<template>
  <div class="level">
    <div>
      <h2>System zarządzania treścią</h2>
      <p>Tworzenie programów ułatwiające zarządzaniem witryną internetową na urządzeniach desktopowych oraz mobilnych.</p>
    </div>
    <div id="images">
      <v-img
        width="600"
        src="../assets/cms_laptop.webp"
        alt="telefony"
      />
      <v-img
        id="imgPhone"
        width="300"
        src="../assets/cms_phone.webp"
        alt="telefony"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.level
{
  margin-top: clamp(50px, 6vw, 80px);
  margin-bottom: 60px;

  h2
  {
    line-height: 1.1em;
  }

  p
  {
    margin-top: 20px;
  }

  #images
  {
    margin-left: 30px;
    position: relative;

    #imgPhone
    {
      position: absolute;
      left: -30px;
      bottom: -20px;
    }
  }
}

@media (min-width: 960px)
{
  .level
  {
    display: flex;
  }
}

@media not screen and (min-width: 960px)
{
  #images
  {
    margin-top: 50px;
    display: flex;
  }
}
</style>
