<template>
  <div id="container">
    <div class="level">
      <article>
        <header>
          <h2>Cena</h2>
          <p>Jest zależna od ilości kontentu jak i przeznaczenia witryny internetowej oraz posiadanych funkcjonalności, dlatego kwota jest ustalana indywidualnie. Poniżej zostają przedstawione orientacyjne ceny będące odniesieniem ile mniej więcej może kosztować kupno witryny internetowej.</p>
        </header>
        <div>
          <section>
            <v-card :elevation="16">
              <h3>999 zł</h3>
              <div>+ 8.5 VAT</div>
              <p>Witryna one page zalecana dla firmy zaczynającej swą działalność. Posiada jedną stronę zawierającą wszystkie informacje o firmie np. czym się zajmuje, gdzie się znajduje, dane kontaktowe itd.</p>
            </v-card>
          </section>
          <section>
            <v-card :elevation="16">
              <h3>1499 zł</h3>
              <div>+ 8.5 VAT</div>
              <p>Witryna posiadająca wiele podstron. Zalecana dla osób, które potrzebują zamieścić w witrynie internetowej dużą ilość informacji o firmie i jej usługach lub/oraz do prowadzenia bloga.</p>
            </v-card>
          </section>
          <section>
            <v-card :elevation="16">
              <h3>1999 zł</h3>
              <div>+ 8.5 VAT</div>
              <p>Witryna posiadająca ponad 5 podstron, sklep internetowy, niestandardowy program. Do niestandardowych programów zaliczają się aplikacje, które rzadko się tworzy jak np. rezerwacja biletów, gra przeglądarkowa itp.</p>
            </v-card>
          </section>
        </div>
      </article>
    </div>
  </div>
</template>

<style scoped lang="scss">
#container
{
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
  background-color: #f2f2f2;

  header
  {
    position: sticky;
    z-index: 1;

    h2
    {
      margin-bottom: 10px;
      font-weight: 900;
    }
  }

  article>div
  {
    margin-top: clamp(30px, 3vw, 60px);
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    @media (min-width: 885px)
    {
      section>div
      {
        margin: 30px;
      }
    }

    @media not screen and (min-width: 885px)
    {
      section>div
      {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

    section>div
    {
      margin-top: 40px;
      min-height: 397px;
      max-width: 350px;
      padding: 30px;
      border-radius: 15px;

      h3
      {
        margin-top: 10px;
        font-size: 40px;
        text-align: center;
      }

      >div:not(:first-child)
      {
        margin-bottom: 30px;
        padding-bottom: 25px;
        font-size: 20px;
        text-align: center;  
        font-weight: 500;
        border-bottom: solid 2px #999999;
      }

      p
      {
        font-size: 16px;
      }
    }
  }
}
</style>