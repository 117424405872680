<template>
  <div class="level">
    <div id="information">
      <header>
        <v-img
          width="100"
          src="../../public/logo.png"
          alt="logo"
        >
        </v-img>
        <h1>Podziel się swoim pomysłem, a ten zostanie zrealizowany</h1>
      </header>
      <div>
        <v-icon
          icon="mdi-email-outline"
          size="large"
        />
        <a href="mailto:architecture.programowanie@gmail.com">architecture.programowanie@gmail.com</a>
      </div>
    </div>
    <form @submit.prevent="send" enctype="text/plain">
      <header><h2>Napisz wiadomość</h2></header>
      <v-text-field
        id="email"
        v-model="data.email"
        :rules="[rules.required, rules.emailLength]"
        clearable
        variant="outlined"
        label="Email"
        type="email"
      ></v-text-field>
      <v-textarea
        v-model="data.content"
        :rules="[rules.required, rules.contentLength]"
        clearable
        label="Treść wiadomości"
        variant="outlined">
      </v-textarea>
      <v-checkbox
        v-model="selected"
        :base-color="baseColor"
        color="#1a90e5"
      > 
        <template v-slot:label>
          <span>
            Wyrażam zgodę na przetwarzanie moich danych osobowych zawartych w
            <router-link to="/polityka-prywatnosci" target="_blank">polityce prywatności</router-link>
            oraz formularzu kontaktowym przez Administratora w celu skontaktowania się i bezpieczeństwa witryny internetowej.
          </span>
        </template>
      </v-checkbox>
      <v-btn
        id="button"
        type="submit"
        variant="tonal"
      >
        Wyślij wiadomość
      </v-btn>
    </form>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import axios from 'axios'

const rules =
{
  required: value => !!value || "Pole nie może być puste",
  emailLength: value => value.length < 254 || "Adres email jest za długi",
  contentLength: value =>
  {
    if (value.length < 60)        return "Treść wiadomości jest za krótka"
    else if (value.length > 1000) return "Treść wiadomości jest za za długa"
    return
  }
}

const data = ref({
  email: "",
  content: ""
})

const selected = ref(false)
const baseColor = ref("black")

function validation(data)
{
  if (selected.value == false)
  {
    baseColor.value = "red"
    return "By wysłać wiadomość trzeba wryrażyć zgodę na przetwarzanie swoich danych osobowych"
  }
  else if (data.email == "" || data.content == "")  return "Uzupełnij dane"
  else if (data.content.length < 60)                return "Treść wiadomości jest za krótka"
  else if (data.content.length > 1000)              return "Treść wiadomości jest za za długa"
  else if (data.email.length > 254)                 return "Adres email jest za długi"
  return null
}

async function send(e)
{
  const validationn = validation(data.value)

  if (validationn != null)
  {
    alert(validationn)
    return
  }

  await axios.post("/api/Message", data.value)
    .then(() => {
        alert("Wysłano wiadomość")
        data.value = ""
        selected.value = false
      }
    )
    .catch(error => {
      const status = error.response.status
      
      if (status == 500)        return alert("Błąd serwera")
      else if (status == 400)   return alert(error.response.data)
      else                      return alert(status)
    })
    
  e.preventDefault()
}
</script>

<style scoped lang="scss">
#information
{
  text-align: center;

  .v-img
  {
    display: block;
    margin: 48px auto;
  }
  
  >div
  {
    margin-top: 30px;

    >a
    {
      margin-left: 10px;
      color: black;
    }

    >a:hover
    {
      color: #1a90e5;
    }
  }
}

form
{
  h2
  {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 26px;
  }

  form>div:not(:first-child):not(:last-child)
  {
    margin-top: 10px;
  }

  #button
  {
    display: block;
    margin-top: 10px;
    margin-left: auto;
  }
}

@media (min-width: 960px)
{
  .level
  {
    display: flex;

    >#information
    {
      width: 50%;
      padding: 20px;
    }

    >form
    {
      width: 50%;
      padding: 20px;
    }
  }
}

@media not screen and (min-width: 960px)
{
  #information
  {
    margin-bottom: 100px;
  }

  form
  {
    margin-bottom: 25px;
  }
}
</style>

<style lang="scss">
textarea
{
  height: 30vh;
}

.v-checkbox .v-label
{
  font-size: 13px;
}

</style>