<template>
  <div class="level">
    <article>
      <header><h2>WITRYNA INTERNETOWA BĘDZIE &nbsp; ZAWIERAŁA</h2></header>
      <div>
        <p><span>SEO</span>, lepsze pozycjonowanie podczas wyświetlania wyników w przglądarce Google</p>
        <p><span>RWD</span>, dostosowanie zawartości kontentu do danego urządzenia</p>
        <p><span>JSON</span>, format to przechowywania danych, który daje szybszy transfer danych i większe bezpieczeństwo niż w przypadku XML</p>
      </div>
    </article>
  </div>
</template>

<style scoped lang="scss">
article
{
  margin: clamp(50px, 6vw, 80px) auto;
  max-width: 725px;

  h2
  {
    font-weight: 900;
    line-height: 1.3em;
  }

  div
  {
    margin-top: clamp(40px, 5vw, 50px);

    p
    {
      margin-top: clamp(15px, 3vw, 30px);
      font-weight: 500;
      line-height: 1.3em;

      span
      {
        color: #349CE8;
        font-weight: 800;
      }
    }
  }
}
</style>