<template>
  <div id="banner">
    <div>
      <h1>ARCHITECTURE</h1>
    </div>
  </div>
  <div id="sectionHero">
    <div>
      <div class="level">
        <v-img
          id="background"
          src="../assets/phones.webp"
          alt="telefony"
        />
        <header>
          <h1>
            Projektowanie witryn
            <br/>
            internetowych
          </h1>
          <p>
            Tworzenie programów według najnowszych trendów
            <br/>
            technologicznych oraz graficznych dopasowanych
            <br/>
            do wspóczesnych czasów.
          </p>
          <router-link to="/kontakt" @click="store.commit('scrollToTop')">Napisz wiadomość</router-link>
        </header>
        <span></span>
      </div>
    </div>
  </div>
  <Contents/>
  <Price/>
  <CMS/>
</template>

<script setup>
import Contents from '../components/ContentsComponent.vue'
import Price from '../components/PriceComponent.vue'
import CMS from '../components/CMSComponent.vue'

import { useStore } from 'vuex'
const store = useStore()

onmousemove = () =>
{
 const image = document.getElementById("background")

  if (image == null)  return
    
  const x = event.clientX
  const y = event.clientY

  const rect = image.getBoundingClientRect()
  const centerX = rect.left + (rect.right - rect.left) / 2
  const centerY = rect.top + (rect.bottom - rect.top) / 2
  let valueX = (x - centerX) / 40;
  let valueY = (y - centerY) / 40;

  valueX = Math.min(Math.max(valueX, -5), 5)
  valueY = Math.min(Math.max(valueY, -5), 5)

  image.style.transform = "rotateX(" + valueY + "deg) rotateY(" + valueX + "deg)"
}
</script>

<style scoped lang="scss">
@import '../scss/home.scss';
@import '../scss/homeLandscape.scss';
@import '../scss/homePortrait.scss';
</style>
