<template>
  <div class="level">
    <article>
      <header><h1>Polityka prywatności</h1></header>
      <ol>
        <li>
          <section>
            <header>Odpowiedzialność za dane</header>
            <p>Administratorem danych osobowych jest <b>"ARCHITECTURE" ROBERT KLINGER</b>.</p>
          </section>
        </li>
        <li>
          <section>
            <header>Celem zbieranych informacji</header>
            <p>Przekazane dane osobowe przetwarzane będą w celu realizacji usług, udzielenia odpowiedzi na wiadomości oraz dla bezpieczeństwa witryny internetowej.</p>
          </section>
        </li>
        <li>
          <section>
            <header>Przetwarzane dane</header>
            <p>Przetwarzanymi danymi osobowymi potrzebnymi do spełniania usług oraz dla bezpieczeństwa witryny internetowej jest adres email oraz IP.</p>
          </section>
        </li>
        <li>
          <section>
            <header>Odbiorcy danych osobowych</header>
            <p>Pani/Pana dane osobowe mogą być przekazywane podmiotom przetwarzającym dane osobowe na zlecenie administratora: dostawcy usług IT.</p>
          </section>
        </li>
        <li>
          <section>
            <header>Czy dane są przekazywane do państw trzecich?</header>
            <p>Państwa dane <span>nie</span> będą przekazywane do państwa trzeciego ani organizacji międzynarodowej.</p>
          </section>
        </li>
        <li>
          <section>
            <header>Prawa użytkownika</header>
            <p>Posiadają Państwo prawo dostępu do treści swoich danych oraz prawo ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych, prawo wniesienia sprzeciwu, prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.</p>
          </section>
        </li>
        <li>
          <section>
            <header>Czy dane są profilowane?</header>
            <p>Dane udostępnione przez Panią/Pana <span>nie</span> będą podlegały profilowaniu.</p>
          </section>
        </li>
        <li>
          <section>
            <header>Informacje kontaktowe</header>
            <p>Adres email: <a href="mailto:architecture.programowanie@gmail.com">architecture.programowanie@gmail.com</a></p>
          </section>
        </li>
      </ol>
    </article>
  </div>
</template>

<style scoped lang="scss">
article
{
  margin: 50px auto;
  max-width: 725px;

  h1
  {
    margin-bottom: 50px;
    font-size: 48px;
    color: #1a90e5;
  }

  li
  {
    margin-top: 25px;
    margin-left: 36px;
    width: calc(100% - 36px);

    header
    {
      margin-bottom: 6.25px;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.3em;
    }
    
    p
    {
      font-size: 16px;
    }

    span
    {
      font-weight: 900;
    }
  }

  li::marker
  {
    font-size: 24px;
    font-weight: bold;
  }
}
</style>